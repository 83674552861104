<template>
  <div class="bg-white p-4  md:p-6 modal-md rounded-lg">
    <div class="text-xl my-8 mt-4 font-semibold font-merri text-center">
      Skip a month...
    </div>
    <div class="space-y-4">
      <div class="px-2">
        <p>
          Already got enough food or want to take a bit of a break, that's cool
          with us.
        </p>
      </div>

      <div
        v-if="hasError"
        class="border-2 border-red-400 bg-red-100 p-4 py-3 text-red-400 rounded mb-4 error-msg"
      >
        Oh no... there was an error. {{ errorMessage }}
      </div>

      <div class="rounded bg-orange-200 p-3 px-4 text-center">
        Your next payment will be on
        <div class="font-semibold text-xl py-2">
          {{ getNewPaymentDate }}
        </div>
      </div>
      <div class="space-y-2">
        <transition name="fade" mode="out-in">
          <div
            v-if="hitLimit"
            key="custom-error-message"
            class=" bg-red-100 p-4 py-3 text-red-400 rounded error-msg"
          >
            Maximum of {{ messageLimit }} characters.
          </div>
        </transition>
        <textarea
          v-model="pauseReason"
          :maxlength="messageLimit"
          v-if="!this.redirect"
          :class="{ 'error-input': hitLimit }"
          placeholder="To help us improve our service, please tell us why you've decided to skip a month."
        ></textarea>
      </div>

      <div class="">
        <form @submit.prevent="submitPause" novalidate="true">
          <loading-button
            :is-loading="isLoading"
            :is-enabled="formValid"
            :is-full="true"
            class="mt-4"
            label="Confirm"
          />
        </form>
      </div>
      <div class="text-center text-gray-600 mt-6">
        <a @click="cancelModal">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script>
import { addMonths, format, fromUnixTime } from 'date-fns';
import LoadingButton from '@/components/LoadingButton';
import { mapGetters } from 'vuex';
import http from '@/http';
export default {
  props: ['data', 'redirect'],
  components: {
    LoadingButton
  },

  computed: {
    formValid: function() {
      if (
        (this.pauseLength === '0' && !this.customDays) ||
        this.customError ||
        this.hitLimit
      ) {
        return false;
      }

      return true;
    },
    isPaused() {
      return this.subscription.ispaused;
    },
    pauseDays() {
      return 31;
    },
    hitLimit() {
      return this.pauseReason.length === this.messageLimit;
    },
    customError() {
      return (
        Number(this.pauseLength) === 0 && Number(this.customDays) > this.maxDays
      );
    },
    ...mapGetters({
      subscription: 'getCurrentSubscription'
    }),
    getNewPaymentDate() {
      const date = fromUnixTime(this.subscription.nextbilldate / 1000);
      return format(addMonths(date, 1), 'do MMMM, yyyy');
    }
  },
  data() {
    return {
      password: '',
      isLoading: false,
      hasError: false,
      errorMessage: '',
      pauseLength: 1,
      pauseReason: '',

      maxDays: 60,
      messageLimit: 500
    };
  },
  methods: {
    submitPause: function() {
      this.isLoading = true;
      this.hasError = false;
      http
        .put(
          `${
            this.subscription.isOnTrial
              ? '/subscripton/extendtrial'
              : '/subscripton/pause'
          }`,
          {
            days: this.pauseDays,
            reason: this.pauseReason
          }
        )
        .then(response => {
          this.isLoading = false;

          this.$store.dispatch('sendMpEvent', {
            event: 'Skip Month - Success',
            properties: {
              location: this.$route.name,

              type: 'EVT',
              days: this.pauseDays,
              unpause: this.getNewPaymentDate,
              reason: this.pauseReason
            }
          });
          let data = response;
          data.forward = true;
          this.$store.dispatch('getSubscription');
          this.$store.dispatch('getUser');

          this.closeModal(this.redirect);
        })
        .catch(error => {
          this.isLoading = false;
          this.hasError = true;
          this.errorMessage = error.response.data.error.message;

          this.$store.dispatch('sendMpEvent', {
            event: 'Skip Month - Error',
            properties: {
              location: this.$route.name,

              type: 'EVT',
              error: error.response.data.error.message
                ? error.response.data.error.message
                : 'Unknown Error'
            }
          });
          throw Error(error.response.data.error.message);
        });
    },
    closeModal: function(redirect) {
      this.$parent.$emit('make-close', redirect);
    },
    cancelModal() {
      this.closeModal();
    }
  },
  mounted() {
    this.$store.dispatch('sendMpEvent', {
      event: 'Skip Month View',
      properties: {
        location: this.$route.name,

        type: 'EVT'
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
